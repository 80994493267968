import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import autobind from 'class-autobind';
import { actions, selectors } from 'Custom';
import SidebarComponent from 'Components/user/sidebar';

class SidebarContainer extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);

        this.state = {
            displayMobileMenu: false,
        }
    }


    toggleMobileMenu() {
        this.setState({
            displayMobileMenu: !this.state.displayMobileMenu
        })
    }

    render() {
        return (
            <SidebarComponent {...this.props} {...this.state} toggleMobileMenu={this.toggleMobileMenu} />
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        user: selectors.user.getData(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        logout: actions.user.logout,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(SidebarContainer);