import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { actions, selectors } from 'Custom';
import queryString from 'query-string';
// import HeaderComponent from 'Components/header';

class OAuthContainer extends React.Component {

    componentDidMount() {
        const params = queryString.parse(this.props.location.search);

        console.log(this.props, params);
    }

    render() {
        return (
            <div></div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(OAuthContainer);