import React from 'react';
import styles from 'Styles';
import Sidebar from 'Containers/user/sidebar';
import AddToSlack from 'Components/user/add-to-slack';
import ReactStripeModal from 'react-stripe-modal';
import Moment from 'react-moment';

export default props => {
    const {
        onSubmit, user, unsubscribe, renewSubscription,
        openStripeModal, closeStripeModal, errorMessage, open
     } = props;

    let content = null;

    if (user.subscription) {
        // user is already subscribed, great!

        content = (
            <div className={styles.content.content}>
                <AddToSlack {...props} />

                <div className={styles.content.title}>Account</div>

                <table>
                    <tbody>
                        <tr>
                            <td>Plan:</td>
                            <td>{user.subscription.name}</td>
                        </tr>
                        <tr>
                            <td>Monthly Price</td>
                            <td>${user.subscription.price/100}.00 {user.subscription.currency}</td>
                        </tr>

                        <tr>
                            <td>Next Payment:</td>
                            {!user.subscription.cancelled ?
                                <td><Moment unix format={"dddd, MMMM Do YYYY"}>{user.subscription.periodEnd}</Moment> <span className={styles.content.link} onClick={unsubscribe}>Cancel Subscription</span></td>
                            : <td>None - subscription cancelled. <span className={styles.content.link} onClick={renewSubscription}>Renew Subscription</span></td>}
                        </tr>
                        <tr>
                            <td>Card Brand:</td>
                            <td>{user.card.brand} <span className={styles.content.link} onClick={openStripeModal}>Change Card Details</span></td>
                        </tr>
                        <tr>
                            <td>Card Expiry:</td>
                            <td>{user.card.expiryYear}/{user.card.expiryMonth}</td>
                        </tr>
                        <tr>
                            <td>Card Last 4:</td>
                            <td>**** {user.card.last4}</td>
                        </tr>
                    </tbody>
                </table>

                <ReactStripeModal
                    open={open}
                    stripePublicKey={process.env.STRIPE_API_KEY}
                    headerBackgroundColor={"#28a58a"}
                    headerColor={"#fff"}
                    buttonStyle={{ backgroundColor: "#28a58a", borderColor: "#28a58a" }}
                    customerEmail={user.email}
                    customerName={user.name}
                    onSubmit={(token) => onSubmit(token, 'update')}
                    submitLabel={"Upgrade Account"}
                    onCancel={closeStripeModal}
                    errorMessage={errorMessage}
                />
            </div>
        );
    } else {
        content = (
            <div className={styles.content.content}>
                <AddToSlack {...props} />

                <div className={styles.content.title}>Upgrade Account</div>

                <table>
                    <tbody>
                        <tr>
                            <td>Plan:</td>
                            <td>QuickPoll.io Free Plan</td>
                        </tr>
                        <tr>
                            <td>Monthly Price</td>
                            <td>$0</td>
                        </tr>
                    </tbody>
                </table>

                <input type="button" className={styles.content.upgrade} onClick={openStripeModal} value={"Upgrade account"} />

                <ReactStripeModal
                    open={open}
                    stripePublicKey={process.env.STRIPE_API_KEY}
                    headerBackgroundColor={"#28a58a"}
                    headerColor={"#fff"}
                    buttonStyle={{ backgroundColor: "#28a58a", borderColor: "#28a58a" }}
                    customerEmail={user.email}
                    customerName={user.name}
                    onSubmit={(token) => onSubmit(token, 'upgrade')}
                    submitLabel={"Upgrade Account"}
                    onCancel={closeStripeModal}
                    errorMessage={errorMessage}
                />
            </div>
        );
    }

    return (
        <div className={styles.main.wrapper}>
            <Sidebar />
            <div className={styles.content.container}>
                {content}
            </div>
        </div>
    )
}