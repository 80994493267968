import React from 'react';
import { Link } from 'react-router-dom';
import styles from 'Styles';
import HeaderContainer from 'Containers/header';
import FooterContainer from 'Containers/footer';

export default props => {
    return (
        <div className={styles.main.container}>
            <HeaderContainer />

            <div className={styles.landing.container}>
                <div className={styles.landing.body}>
                    <div className={styles.landing.content}>
                        <h1>FAQ</h1>

                        <div className={styles.landing.faq}>
                            <h3>What is QuickPoll?</h3>
                            <p>QuickPoll is a Slack application, used to create polls in your workspace. Quickly survey your team from a channel in your workspace.</p>
                        </div>
                        <div className={styles.landing.faq}>
                            <h3>Where does QuickPoll work?</h3>
                            <p>QuickPoll works everywhere inside your Slack workspace. If it doesn't, <Link to="/support">let us know</Link>!</p>
                        </div>
                        <div className={styles.landing.faq}>
                            <h3>Once installed, who can create polls?</h3>
                            <p>After installing QuickPoll, anyone in your workspace can use QuickPoll.</p>
                        </div>
                        <div className={styles.landing.faq}>
                            <h3>How do I create a new poll?</h3>
                            <p>Use the slash command <code>/quickpoll</code> to open the create poll dialog. Or if you are on the free version with no more dialog poll creation, you can write a longer slash command. For example:</p>
                            <p><code>/quickpoll "This is my question" "Option 1" "Option 2" "Option 3"</code></p>
                            <p>See the <Link to="/">homepage</Link> for some examples</p>
                        </div>
                        <div className={styles.landing.faq}>
                            <h3>How do I limit votes?</h3>
                            <p>To limit voting, simply use the normal slash command with the <code>limit</code> option. Use the keyword limit followed by the number of votes per user. e.g.</p>
                            <p><code>/quickpoll "question" "option1" "option2" limit 1</code></p>
                            <p>See the <Link to="/">homepage</Link> for some examples</p>
                        </div>
                        <div className={styles.landing.faq}>
                            <h3>How do I make votes anonymous?</h3>
                            <p>To make votes anonymous, add the <code>anonymous</code> keyword to your slash command. e.g.</p>
                            <p><code>/quickpoll "question" "option1" "option2" limit 1</code></p>
                            <p>See the <Link to="/">homepage</Link> for some examples</p>
                        </div>
                        <div className={styles.landing.faq}>
                            <h3>How do I remove my vote after I've voted?</h3>
                            <p>To remove your vote, click the same button you clicked to cast your vote initially and your vote will be removed.</p>
                        </div>
                        <div className={styles.landing.faq}>
                            <h3>What is QuickPoll Business?</h3>
                            <p>QuickPoll Business is the paid version of QuickPoll and costs $40 per month.</p>
                        </div>
                        <div className={styles.landing.faq}>
                            <h3>What features does QuickPoll Business have?</h3>
                            <ul>
                                <li>Limit user votes</li>
                                <li>Create polls using Slack Dialogs</li>
                                <li>Add options to existing polls</li>
                                <li>Unlimited options per poll</li>
                                <li>Unlimited monthly votes</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <FooterContainer />
        </div>
    )
};