import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'Custom';
import HeaderComponent from 'Components/header';

class HeaderContainer extends React.Component {

    render() {
        return (
            <HeaderComponent {...this.props} />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: selectors.user.getData(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(HeaderContainer);