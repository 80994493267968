// Generate reducers
export default (constants, customReducers = {}) => {
    const prefixAction = (type, action) => `${constants.REDUX_PREFIX}${type.toLocaleUpperCase()}/${action}`;
    const snakeToCamel = string => string.toLocaleLowerCase().replace(/_(\w)/g, (m => m[1].toUpperCase()));
    const handleCustomReducer = (customReducer, state, action, prevState) => customReducer ? customReducer(state, action, prevState) : state;

    const reducers = Object.keys(constants.actionTypes).reduce((acc, key) => {
        // Group = user, services, etc. The name of the state object
        let group = constants.actionTypes[key];
        const customGroupReducer = customReducers[key] || {};

        return {
            ...acc,
            [key]: Object.keys(group).filter(key => !(key.indexOf('_SUCCESS') > -1 || key.indexOf('_FAILURE') > -1)).reduce((handlers, type) => {
                const readableAction = snakeToCamel(type);
                let actionStandard = type;
                let actionSuccess = `${type}_SUCCESS`;
                let actionFailure = `${type}_FAILURE`;
                let prefixedStandard = prefixAction(key, actionStandard);
                let prefixedSuccess = prefixAction(key, actionSuccess);
                let prefixedFailure = prefixAction(key, actionFailure);
                return {
                    ...handlers,
                    [prefixedStandard]: (state, action, prevState) => {
                        state.pending[readableAction] = true;
                        state.errors[readableAction] = null;
                        return handleCustomReducer(customGroupReducer[snakeToCamel(actionStandard)], state, action, prevState);
                    },
                    [prefixedSuccess]: (state, action, prevState) => {
                        state.pending[readableAction] = false;
                        state.data = { ...state.data, ...(action.data || {}) };
                        return handleCustomReducer(customGroupReducer[snakeToCamel(actionSuccess)], state, action, prevState);
                    },
                    [prefixedFailure]: (state, action, prevState) => {
                        state.pending[readableAction] = false;
                        state.errors[readableAction] = action.message || "Unknown error occured";
                        return handleCustomReducer(customGroupReducer[snakeToCamel(actionFailure)], state, action, prevState);
                    }
                }
            }, {})
        }
    }, {});

    return Object.keys(constants.actionTypes).reduce((acc, key) => {
        return {
            ...acc,
            [key]: (state = constants.initialStates[key], action) => {
                if (reducers[key][action.type]) {
                    return reducers[key][action.type](JSON.parse(JSON.stringify(state)), action, state);
                }
                return state;
            }
        }
    }, {});
};