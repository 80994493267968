// Define your constants here
const actionTypes = {
    user: ['FETCH', 'FETCH_POLLS', 'LOGOUT', 'SUBSCRIBE', 'UPDATE_PAYMENT_SOURCE', 'UNSUBSCRIBE', 'RENEW_SUBSCRIPTION', 'SEND_SUPPORT_MESSAGE'],
};

// Define custom initial states here
const customInitialStates = {
    user: {
        // my custom object
    },
};

const customActions = {};
const customReducers = {
    user: {
        fetchPollsSuccess: (newState, action = {}) => {
            console.log("action.polls", action.polls);
            newState.polls = action.polls || [];
            return newState;
        },
        logout: (newState, action = {}) => {
            return {
                errors: {},
                pending: {},
                data: null,
            }
        }
    }
};
const customSelectors = {
    user: {
        getPolls: (state) => {
            return state.user.polls || [];
        },
    }
};


/** 
 * WARNING
 * Do NOT modify below 
 */
import generate from 'Generators';
const constants = generate.constants(actionTypes, customInitialStates);
const actions = generate.actions(constants, customActions);
const reducers = generate.reducers(constants, customReducers);
const selectors = generate.selectors(constants, customSelectors);

export { 
    actions,
    reducers,
    selectors,
    constants,
}