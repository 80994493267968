import React from 'react';
import styles from 'Styles';
import Sidebar from 'Containers/user/sidebar';

export default props => {

    return (
        <div className={styles.main.wrapper}>
            <Sidebar />
            <div className={styles.content.container}>
                <div className={styles.content.content}>
                    <div className={styles.content.title}>Dashboard</div>
                </div>
            </div>
        </div>
    )
}