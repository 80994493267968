import React from 'react';
import { Link } from 'react-router-dom';
import styles from 'Styles';
import HeaderContainer from 'Containers/header';
import FooterContainer from 'Containers/footer';

export default props => {
    const { user } = props;
    return (
        <div className={styles.main.container}>
            <HeaderContainer />
            <div className={styles.landing.container}>
                <div className={styles.landing.body}>
                    <div className={styles.landing.content}>
                        <div className={styles.landing.section}>
                            <div className={styles.landing.sectionContent}>
                                <div className={styles.landing.h1}>Quick Poll has been installed!</div>
                                <div className={styles.landing.info}>
                                    Want to view your poll history and manage your account?&nbsp;
                                    {user ?
                                        <Link to="/user" className={`${styles.landing.menuItem} ${styles.landing.menuItemMobile}`}>Dashboard</Link>
                                    :
                                        <a className={styles.landing.landingSignIn} href={process.env.SLACK_SIGN_IN_LINK}>
                                            <img alt="Sign in with Slack" src="/assets/sign-in-with-slack.png" />
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={styles.landing.section}>
                            <div className={styles.landing.sectionContent}>
                                <div className={styles.landing.h1}>Get started</div>
                                <div className={styles.landing.info}>
                                    <p>Open your Slack workspace and run the poll command:</p>
                                    <div className={styles.landing.command}>/quickpoll</div>
                                    <p><i>If you're using the free version, you can only create 10 polls per month using dialogs.</i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FooterContainer />
        </div>
    );
}