import generateConstants from './constants';
import generateActions from './actions';
import generateSelectors from './selectors';
import generateReducers from './reducers';

const constants = (actionTypes = {}, custom = {}) => generateConstants(actionTypes, custom);
const actions = (constants, custom = {}) => generateActions(constants, custom);
const selectors = (constants, custom = {}) => generateSelectors(constants, custom);
const reducers = (constants, custom = {}) => generateReducers(constants, custom);

export default {
    actions,
    reducers,
    selectors,
    constants,
}
