import React from 'react';
import styles from 'Styles';
import HeaderContainer from 'Containers/header';
import FooterContainer from 'Containers/footer';

export default props => {
    return (
        <div className={styles.main.container}>
            <HeaderContainer />

            <div className={styles.landing.container}>
                <div className={styles.landing.body}>
                    <div className={styles.landing.content}>
                        <h4>SERVICE TERMS AND CONDITIONS</h4>

                        <p>Last updated: 10 September 2019</p>
                        
                        <h4>PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS WEBSITE.</h4>

                        <p>Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy and website disclaimer, govern QuickPoll.io’s relationship with you in relation to your use of this website.</p>

                        <p>By using this website, you signify your acceptance of these terms and conditions of use. For the purposes of these terms and conditions, “Us”, “Our” and “We” refers to QuickPoll.io and “You” and “Your” refers to you, the client, visitor, website user or person using our website.</p>

                        <h4>AMENDMENT OF TERMS</h4>

                        <p>We reserve the right to change, modify, add or remove portions of these terms at any time. Please check these terms regularly prior to using our website to ensure you are aware of any changes. We will endeavour to highlight any significant or substantive changes to you where possible. If you choose to use our website then we will regard that use as conclusive evidence of your agreement and acceptance that these terms govern your and QuickPoll.io’s rights and obligations to each other.</p>

                        <h4>DESCRIPTION OF SERVICE</h4>

                        <p>QuickPoll.io allows you to create basic polls in a Slack channel, that all channel members can vote on. The topic and options for the poll is defined by you. Poll responses can be anonymous if specified during poll creation.</p>

                        <p>QuickPoll.io may, in the future, update current services and/or features or offer new services and/or features to the QuickPoll.io Service (including, the release of new tools). Such updated or new features and/or services shall be subject to the terms and conditions of the Agreement.</p>

                        <p>Access to the QuickPoll.io Service is permitted on a temporary basis. We reserve the right to modify or discontinue offering or updating the QuickPoll Service at any time without notice.</p>

                        <h4>NO SLACK ASSOCIATION</h4>
                        <p>QuickPoll.io and Slack Technologies Inc, the provider of the Slack communication service, (“Slack”) are different entities. There is no relationship between QuickPoll.io and Slack, other than QuickPoll.io being a licensee and user of the Slack API for the purpose of providing the QuickPoll.io Service. Slack is not responsible for the QuickPoll.io Service and will not provide support for the QuickPoll.io Service.</p>
                            
                        <p>These terms do not apply to your use of the Slack services. Such use is governed by the Slack Terms of Service available on the website <a href="https://slack.com/" target={"blank"}>slack.com</a>.</p>

                        <h4>LIMITATION OF LIABILITY</h4>

                        <p>It is an essential pre-condition to you using our website that you agree and accept that QuickPoll.io is not legally responsible for any loss or damage you might suffer related to your use of the website, whether from errors or from omissions in our documents or information, any goods or services we may offer or from any other use of the website. This includes your use or reliance on any third party content, links, comments or advertisements. Your use of, or reliance on, any information or materials on this website is entirely at your own risk, for which we shall not be liable.</p>

                        <p>It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific, personal requirements. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p>

                        <h4>COMPETITION AND CONSUMER ACT</h4>

                        <p>For the purposes of Schedule 2 of the Australian Consumer Law, in particular Sections 51 to 53, 64 and 64A of Part 3-2, Division 1, Subdivision A of the Competition and Consumer Act 2010 (Cth), QuickPoll.io’s liability for any breach of a term of this agreement is limited to: the supplying of the goods or services to you again; the replacement of the goods; or the payment of the cost of having the goods or services supplied to you again.</p>

                        <p>You must be over 18 years of age to use this website and to purchase any goods or services.</p>

                        <h4>DELIVERY OF GOODS</h4>

                        <p>Physical goods may be delivered by Australia Post and/or other reputable courier companies. Deliveries are processed promptly upon receipt of full payment. Delivery may take between 2 and 14 days, depending on the delivery option. Damaged or lost orders should be resolved with Australia Post or the courier company directly and we are not responsible for goods that are damaged in transit or not received. Replacement of damaged or lost items is made at the discretion of QuickPoll.io.</p>

                        <p>Digital goods are delivered immediately. Please be aware there are inherent risks associated with downloading any software and digital goods. Should you have any technical problems downloading any of our goods, please contact us so we may try to assist you.</p>

                        <h4>RETURNS AND REFUNDS</h4>

                        <p>QuickPoll.io handles returns and processes refunds in accordance with the Australian Consumer Protection legislation.</p>

                        <p>Should you wish to return your order, please notify us within 14 days of purchase with a valid reason for return. If we are unable to resolve your complaint or further assist you, we will process a refund upon timely receipt of the goods purchased. Unopened goods will be refunded in full. Refunds will be processed promptly and payment made by the same method that you made payment. All refunds are made at the discretion of QuickPoll.io.</p>

                        <h4>LINKS TO OTHER WEBSITES</h4>

                        <p>QuickPoll.io may from time to time provide on its website, links to other websites, advertisements and information on those websites for your convenience. This does not necessarily imply sponsorship, endorsement, or approval or arrangement between QuickPoll.io and the owners of those websites. QuickPoll.io takes no responsibility for any of the content found on the linked websites.</p>

                        <p>QuickPoll.io’s website may contain information or advertisements provided by third parties for which QuickPoll.io accepts no responsibility whatsoever for any information or advice provided to you directly by third parties. We are making a ‘recommendation’ only and are not providing any advice nor do we take any responsibility for any advice received in this regard.</p>

                        <h4>DISCLAIMER</h4>

                        <p>To the fullest extent permitted by law, QuickPoll.io absolutely disclaims all warranties, expressed or implied, including, but not limited to, implied warranties of merchantability and fitness for any particular purpose. QuickPoll.io gives no warranty that the documents, goods or services will be free of errors, or that defects will be corrected, or that our website or its server is free of viruses or any other harmful components.</p>

                        <p>Whilst we, at all times endeavour to have the most accurate, reliable and up-to-date information on our website, we do not warrant or make any representations regarding the use or the result of the use of any document, product, service, link or information in its website or as to their correctness, suitability, accuracy, reliability, or otherwise.</p>

                        <p>It is your sole responsibility and not the responsibility of QuickPoll.io to bear any and all costs of servicing, repairs, or correction. The applicable law in your state or territory may not permit these exclusions, particularly the exclusions of some implied warranties. Some of the above may not apply to you but you must ensure you are aware of any risk you may be taking by using this website or any products or services that may be offered through it. It is your responsibility to do so.</p>

                        <h4>YOUR PRIVACY</h4>

                        <p>At QuickPoll.io, we are committed to protecting your privacy. We use the information we collect about you to maximize the services that we provide to you. We respect the privacy and confidentiality of the information provided by you and adhere to the Australian Privacy Principles. Please read our separate Privacy Policy carefully.</p>

                        <p>You may change your details at any time by advising us in writing via email. All information we receive from our customers is protected by our secure servers. QuickPoll.io’s secure server software encrypts all customer information before it is sent to us. Furthermore, all customer data collected is secured against unauthorized use or access. Credit card information is not stored by us on our servers.</p>

                        <h4>THIRD PARTIES</h4>

                        <p>We do not and will not sell or deal in personal or customer information. We may however use in a general sense without any reference to your name, your information to create marketing statistics, identify user demands and assist in meeting customer needs generally. In addition, we may use the information that you provide to improve our website and services but not for any other use.</p>

                        <h4>DISCLOSURE OF INFORMATION</h4>

                        <p>QuickPoll.io may be required, in certain circumstances, to disclose information in good faith and where QuickPoll.io is required to do so in the following circumstances: by law or by any court; to enforce the terms of any of our customer agreements; or to protect the rights, property or safety of our customers or third parties.</p>

                        <h4>EXCLUSION OF COMPETITORS</h4>

                        <p>If you are in the business of creating similar documents, goods or services for the purpose of providing them for a fee to users, whether they be business users or domestic users, then you are a competitor of QuickPoll.io. QuickPoll.io expressly excludes and does not permit you to use or access our website, to download any documents or information from its website or obtain any such documents or information through a third party. If you breach this term then QuickPoll.io will hold you fully responsible for any loss that we may sustain and further hold you accountable for all profits that you might make from such unpermitted and improper use. QuickPoll.io reserves the right to exclude and deny any person access to our website, services or information in our sole discretion.</p>

                        <h4>COPYRIGHT, TRADEMARK AND RESTRICTIONS OF USE</h4>

                        <p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, trademarks and graphics. You are not permitted to reproduce the documents, information or materials on the website for the purposes of sale or the use by any third party. In particular you are not permitted to republish, upload, transmit electronically or otherwise or distribute any of the materials, documents or products that may be available for download from time to time on this website.</p>

                        <p>QuickPoll.io expressly reserves all copyright and trademark in all documents, information and materials on our website and we reserve the right to take action against you if you breach any of these terms.</p>

                        <p>Any redistribution or reproduction of part or all of the contents in any form is prohibited other than the following: you may print or download to a local hard disk extracts for your personal and non-commercial use only; and you may copy the content to individual third parties for their personal use, but only if you acknowledge the website as the source of the material.</p>

                        <p>You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.</p>

                        <h4>WHOLE AGREEMENT</h4>

                        <p>These terms and conditions represent the whole agreement between you and QuickPoll.io concerning your use and access to QuickPoll.io’s website and your use and access to the documents and information on it. No other term is to be included in this agreement except where it is required to be included by any legislation of the Commonwealth or any State or Territory. All implied terms except those implied by statute and which cannot be expressly excluded are hereby expressly excluded.</p>

                        <h4>EXCLUSION OF UNENFORCEABLE TERMS</h4>

                        <p>Where any clause or term above would by any applicable statute be illegal, void, or unenforceable in any State or Territory then such a clause shall not apply in that State or Territory and shall be deemed never to have been included in these terms and conditions in that State or Territory. Such a clause if legal and enforceable in any other State or Territory shall continue to be fully enforceable and part of this agreement in those other States and Territories. The deemed exclusion of any term pursuant to this paragraph shall not affect or modify the full enforceability and construction of the other clauses of these terms and conditions.</p>

                        <h4>COPYWRITING SERVICES</h4>
                        <p>To engage our services, a formal quote must be agreed. All proposals, quotes and projects are subject to the client terms included in our Copywriting Agreement. When you engage our services, you agree that we are reliant on the information supplied by you, the client, in order to provide the services. We cannot be held responsible for any results of our services based on such client information and we cannot be held responsible for how you choose to use the outputs of our services.
                        Our website may contain samples of our client work. You are at no time permitted to copy, modify or use in full, all or any part of these materials.</p>

                        <h4>JURISDICTION</h4>

                        <p>This agreement and this website are subject to the laws of NSW and Australia. If there is a dispute between you and QuickPoll.io that results in litigation then you must submit to the jurisdiction of the courts of NSW.</p>
                    </div>
                </div>
            </div>

            <FooterContainer />
        </div>
    )
}