import React from 'react';
import { Link } from 'react-router-dom';
import styles from 'Styles';

export default props => {
    return (
        <div className={styles.landing.footer}>
            <span>Copyright &copy; {(new Date()).getFullYear()} - support@quickpoll.io</span>
            <div className={styles.landing.rightFooter}>
                <Link to="/faq">FAQ</Link>
                <Link to="/support">Support</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
            </div>
        </div>
    )
};