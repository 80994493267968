import React from 'react';
import styles from 'Styles';
import HeaderContainer from 'Containers/header';
import FooterContainer from 'Containers/footer';

export default props => {
    const { onSubmit, onChange, name, email, topic, message, hidden, sendSupportMessagePending, errors, sent } = props;
    return (
        <div className={styles.main.container}>
            <HeaderContainer />

            <div className={styles.landing.container}>
                <div className={styles.landing.body}>
                    <div className={styles.landing.content}>

                        <form onSubmit={onSubmit} className={styles.landing.form}>
                            <h4>Support</h4>
                            <p>We love to hear from our users. Please feel free to send us an email at support@quickpoll.io or fill out our form below. We aim to reply within 48-hours.</p>
                            <div className={styles.landing.formItem}>
                                <label>Name</label>
                                <input type="text" name="name" value={name} onChange={onChange} disabled={sendSupportMessagePending} />
                            </div>
                            <div className={styles.landing.formItem}>
                                <label>Email</label>
                                <input type="email" name="email" value={email} onChange={onChange} disabled={sendSupportMessagePending}/>
                            </div>
                            <div className={styles.landing.formItem} disabled={sendSupportMessagePending}>
                                <label>Topic</label>
                                <select name="topic" value={topic} onChange={onChange}>
                                    <option value="general">General Support</option>
                                    <option value="feature-request">Feature Request</option>
                                    <option value="billing">Billing Questions</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div className={styles.landing.formItem}>
                                <label>Message</label>
                                <textarea name="message" value={message} onChange={onChange} disabled={sendSupportMessagePending}></textarea>
                            </div>
                            <input type="text" style={{display:'none'}} name="hidden" value={hidden} onChange={onChange} />

                            {errors && Object.keys(errors).length ? <ul className={styles.landing.error}>{Object.values(errors).map(e => <li key={e}>{e}</li>)}</ul> : null}
                            <div className={styles.landing.formItem}>
                                <input type="submit" value="Send Message" disabled={sendSupportMessagePending} /> 
                                {sendSupportMessagePending ? ' sending..' : null}
                                {sent ? ' Message sent' : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <FooterContainer />
        </div>
    );
}
