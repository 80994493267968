import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'Custom';
import autobind from 'class-autobind';
import SupportComponent from 'Components/support';

class SupportContainer extends React.Component {

    constructor(props) {
        super(props);

        autobind(this);

        this.state = {
            name: '',
            email: '',
            topic: 'general',
            message: '',
            hidden: '',
            errors: {},
            sent: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sendSupportMessagePending && !this.props.sendSupportMessagePending) {
            this.setState({
                name: '',
                email: '',
                topic: 'general',
                message: '',
                hidden: '',
                errors: {},
                sent: true
            })
        }
    }

    onChange(evt) {
        this.setState({
            [evt.target.name]: evt.target.value,
            errors: {}
        })
    }

    onSubmit(evt) {
        const { sendSupportMessage } = this.props;
        evt.preventDefault();

        const errors = {};
        // validate non-empty fields
        if (!this.state.name) {
            errors['name'] = "Name cannot be empty";
        }
        if (!this.state.email) {
            errors['email'] = "Email cannot be empty";
        }
        if (!this.state.topic) {
            errors['topic'] = "Topic cannot be empty";
        }
        if (!this.state.message) {
            errors['message'] = "Message cannot be empty";
        }

        if (this.state.hidden) {
            errors['hidden'] = "I think you're a robot."
        }

        if (Object.values(errors).length) {
            // there are errors..
            this.setState({
                errors
            });
        } else {
            sendSupportMessage({
                name: this.state.name,
                email: this.state.email,
                topic: this.state.topic,
                message: this.state.message
            });
        }
        return false;
    }

    render() {
        return (
            <SupportComponent {...this.props} {...this.state} onSubmit={this.onSubmit} onChange={this.onChange} />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: selectors.user.getData(state),
        sendSupportMessagePending: selectors.user.sendSupportMessagePending(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        sendSupportMessage: actions.user.sendSupportMessage
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(SupportContainer);