import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { reducers } from 'Custom';
import sagas from 'Sagas';
import App from 'Containers';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = applyMiddleware(
    routerMiddleware(history),
    sagaMiddleware,
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    combineReducers({
        router: connectRouter(history),
        ...reducers
    }),
    composeEnhancers(middleware)
);

sagaMiddleware.run(sagas);

// setTimeout here to load the .less styles in dev
setTimeout(() => {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App history={history} />
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    )
}, 5);