import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'Custom';
import TermsAndConditionsComponent from 'Components/terms-and-conditions';

class TermsAndConditionsContainer extends React.Component {
    render() {
        return (
            <TermsAndConditionsComponent {...this.props} />
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(TermsAndConditionsContainer);