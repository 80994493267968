(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("pollme-webapp", [], factory);
	else if(typeof exports === 'object')
		exports["pollme-webapp"] = factory();
	else
		root["pollme-webapp"] = factory();
})(self, function() {
return 