import request from './request';

class Api {
    get user() {
        return {
            // Validate the Slack user
            // validate: () => request.raw(`${process.env.API_PATH}/auth/validate`, { 'Content-Type': 'application/json' }, 'GET'),

            logout: () => request.get(`/logout`),
            fetch: () => request.get(`/user`),
            fetchPolls: (page = 0) => request.get(`/user/polls/${page}`),

            subscribe: (source) => request.post(`/user/subscription`, { source }),
            updatePaymentSource: (source) => request.post(`/user/subscription/payment`, { source }),
            unsubscribe: () => request.delete(`/user/subscription`),
            renewSubscription: () => request.post(`/user/subscription/renew`, {}),

            sendSupportMessage: (name, email, topic, message) => request.post(`/support`, { name, email, topic, message }),
        }
    }
}

export default new Api();