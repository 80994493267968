import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { actions, selectors } from 'Custom';
import DashboardComponent from 'Components/user/dashboard';

class DashboardContainer extends React.Component {

    render() {
        return (
            <DashboardComponent {...this.props} />
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(DashboardContainer);