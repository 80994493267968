import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router';
import { actions, selectors } from 'Custom';
import Landing from 'Containers/landing';

import OAuth from 'Containers/oauth';
import Success from 'Containers/success';
import User from 'Containers/user';
import PrivacyPolicy from 'Containers/privacy-policy';
import TermsAndConditions from 'Containers/terms-and-conditions';
import Support from 'Containers/support';
import Faq from 'Containers/faq';

class Container extends React.Component {

    componentDidMount() {
        const { fetchUser, isPending } = this.props;
        // fetch the users data to validate the user
        if (!isPending && localStorage.getItem(process.env.LOCAL_STORAGE_AUTH_KEY)) {
            fetchUser({ landing: true });
        }
        document.body.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/" component={Landing} />
                    <Route exact path="/oauth" component={OAuth} />
                    <Route exact path="/success" component={Success} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                    <Route exact path="/support" component={Support} />
                    <Route exact path="/faq" component={Faq} />
                    <Route path="/user" component={User} />
                    <Redirect to="/" />
                </Switch>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        user: selectors.user.getData(state),
        isPending: selectors.user.fetchPending(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        fetchUser: actions.user.fetch,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(Container);