import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { actions, selectors } from 'Custom';
import FaqComponent from 'Components/faq';

class FaqContainer extends React.Component {

    componentDidMount() {
        document.body.scrollTo(0, 0);
    }

    render() {
        return (
            <FaqComponent {...this.props} />
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(FaqContainer);