export default (constants, customSelectors = {}) => {
    const readState = (state, type) => state[type];
    const snakeToCamel = string => string.toLocaleLowerCase().replace(/_(\w)/g, (m => m[1].toUpperCase()));

    const generateSelectors = (type, action) => {
        let camelKey = snakeToCamel(action);
        return {
            [`${camelKey}Pending`]: (state) => readState(state, type).pending[camelKey],
            [`${camelKey}Errors`]: (state) => readState(state, type).errors[camelKey],
        }
    };

    return Object.keys(constants.actionTypes).reduce((acc, type) => ({
        ...acc,
        [type]: {
            ...Object.keys(constants.actionTypes[type]).reduce((actions, action) => ({
                ...actions,
                ...(generateSelectors(type, action))
            }), {}),
            getData: (state) => readState(state, type).data,
            ...(customSelectors[type] || {})
        }
    }), {});
};