import React from 'react';
import { Link } from 'react-router-dom';
import styles from 'Styles';

export default props => {
    const { user } = props;
    return (
        <div className={styles.landing.menu}>
            <div className={styles.landing.content}>
                <Link to="/" className={styles.landing.logo} onClick={() => window.scrollTo(0, 0)}>
                    <img src="/assets/logo_512.png" />
                    Quick Poll
                </Link>
                <div className={styles.landing.menuItems}>
                    <div className={styles.landing.menuItem}>&nbsp;</div>
                    <Link to="/support" className={`${styles.landing.menuItem} ${styles.landing.menuItemMobile}`}>Support</Link>
                    {/* <div className={styles.landing.menuItem}>Features</div>
                    <div className={styles.landing.menuItem}>Support</div> */}
                    {/* <div className={styles.landing.menuItem}>
                        <a className={styles.landing.menuAddToSlack} href={SLACK_OAUTH_LINK}>
                            <img alt="Add to Slack" src="/assets/add-to-slack.png" />
                        </a>
                    </div> */}
                    {user ?
                        <Link to="/user" className={`${styles.landing.menuItem} ${styles.landing.menuItemMobile}`}>Dashboard</Link>
                    :
                        <div className={`${styles.landing.menuItem} ${styles.landing.menuItemMobile} ${styles.landing.action}`}>
                            <a className={styles.landing.menuAddToSlack} href={process.env.SLACK_SIGN_IN_LINK}>
                                <img alt="Sign in with Slack" src="/assets/sign-in-with-slack.png" />
                            </a>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}