import React from 'react';
import { Link } from 'react-router-dom';
import styles from 'Styles';
import HeaderContainer from 'Containers/header';
import FooterContainer from 'Containers/footer';

export default props => {
    return (
        <div className={styles.main.container}>
            <HeaderContainer />
            <div className={styles.landing.container}>
                <div className={styles.landing.header}>
                    <div className={styles.landing.content}>
                        <div className={styles.landing.info}>
                            <div className={styles.landing.title}>The perfect polling app for Slack</div>
                            <div className={styles.landing.catchline}>Quickly survey your team from a channel in your workspace</div>
                            <div className={styles.landing.catchline}>Start making polls in your Slack workspace in 15 seconds</div>
                            <a className={styles.landing.addToSlack} href={process.env.SLACK_OAUTH_LINK}>
                                <img alt="Add to Slack" src="/assets/add-to-slack.png" />
                            </a>
                            <p><small>By installing or using Quick Poll you agree to our <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.</small></p>
                        </div>

                        <div className={styles.landing.image}>
                            <video src="/assets/quickpoll-example.mp4" loop={true} autoPlay={true} muted={true}></video>
                            {/* <div className={styles.landing.command}>
                                /quickpoll "Is Quick Poll just awesome?" "Yes" "No"
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className={styles.landing.body}>
                    <div className={styles.landing.content}>


                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.section}>
                            <div className={styles.landing.sectionGraphic}>
                                <img src={"/assets/create-view.png"} />
                            </div>
                            <div className={styles.landing.sectionContent}>
                                <div className={styles.landing.h1}>Dialogs</div>
                                <div className={styles.landing.info}>
                                    <p>Use dialogs to create your poll! <small>(limited to 2 create dialogs for free users)</small></p>
                                    <p>Run the /quickpoll command with no parameters, or limit and/or anonymous parameters and the dialog will appear.</p>
                                    <div className={styles.landing.command}>/quickpoll</div>
                                    <div className={styles.landing.command}>/quickpoll limit 1</div>
                                    <div className={styles.landing.command}>/quickpoll anonymous</div>
                                    <div className={styles.landing.command}>/quickpoll anonymous limit 1</div>
                                    <div className={styles.landing.command}>/quickpoll hidden</div>
                                    <div className={styles.landing.command}>/quickpoll hidden anonymous</div>
                                    <div className={styles.landing.command}>/quickpoll hidden anonymous limit 1</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.section}>
                            <div className={styles.landing.sectionGraphic}>
                                <img src={"/assets/example-poll.png"} />
                            </div>
                            <div className={styles.landing.sectionContent}>
                                <div className={styles.landing.h1}>Add Options</div>
                                <div className={styles.landing.info}>
                                    <p>Once created, you will receive a message to add more options to the poll.</p>
                                </div>
                            </div>
                        </div>

                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.section}>
                            <div className={styles.landing.sectionGraphic}>
                                <img src={"/assets/simple.png"} />
                            </div>
                            <div className={styles.landing.sectionContent}>
                                <div className={styles.landing.h1}>Creating a poll is simple</div>
                                <div className={styles.landing.info}>
                                    <div className={styles.landing.h5}>Pick a question, give options and send it!</div>
                                    <div className={styles.landing.command}>/quickpoll "Poll question?" "Option1" "Option2" "Option3"</div>
                                    <p>If you prefer a form, use the /quickpoll command with no question or options.</p>
                                    <div className={styles.landing.command}>/quickpoll</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.section}>
                            <div className={styles.landing.sectionGraphic}>
                                <img src={"/assets/anonymous.png"} />
                            </div>
                            <div className={styles.landing.sectionContent}>
                                <div className={styles.landing.h1}>Anonymous Polls</div>
                                <div className={styles.landing.info}>
                                    <div className={styles.landing.h5}>Don't want your coworkers to know who voted?</div>
                                    <p>We have you covered. Add the word 'anonymous' after your options and their names will be hidden.</p>
                                    <div className={styles.landing.command}>/quickpoll "Poll question?" "Option1" "Option2" "Option3" anonymous</div>
                                    <p>You can run an anonymous poll using dialogs with this command:</p>
                                    <div className={styles.landing.command}>/quickpoll anonymous</div>
                                    <p>Can't always remember how to spell anonymous? We have you covered. You can also use 'anon'</p>
                                    <div className={styles.landing.command}>/quickpoll "Poll question?" "Option1" "Option2" "Option3" anon</div>
                                    <div className={styles.landing.command}>/quickpoll anon</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.section}>
                            <div className={styles.landing.sectionGraphic}>
                                <img src={"/assets/limited-votes.png"} />
                            </div>
                            <div className={styles.landing.sectionContent}>
                                <div className={styles.landing.h1}>Voting Limits</div>
                                <div className={styles.landing.info}>
                                    <div className={styles.landing.h5}>Limiting how many times a user can vote is easy.</div>
                                    <p>Simply add 'limit 1' at the end of your command to limit votes to once per person! or limit 2, limit 3, etc.</p>
                                    <p><small>Only available in the business version <a href={process.env.SLACK_SIGN_IN_LINK}>upgrade</a></small></p>
                                    <div className={styles.landing.command}>/quickpoll "What should we get for lunch?" "Sushi" "Burgers" "Thai" "Sandwiches" limit 1</div>

                                    <p>To limit votes when creating a poll using dialogs, use this command:</p>
                                    <div className={styles.landing.command}>/quickpoll limit 1</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.section}>
                            <div className={styles.landing.sectionGraphic}>
                                <img src={"/assets/hidden-poll.png"} />
                            </div>
                            <div className={styles.landing.sectionContent}>
                                <div className={styles.landing.h1}>Hidden Poll</div>
                                <div className={styles.landing.info}>
                                    <div className={styles.landing.h5}>Prevent votes from influencing other voters.</div>
                                    <p>Add the keyword 'hidden' in your command to create a hidden poll. Votes are not visible to voters, however as the poll creator, you can view the results.</p>
                                    <p><small>Only available in the business version <a href={process.env.SLACK_SIGN_IN_LINK}>upgrade</a></small></p>
                                    <div className={styles.landing.command}>/quickpoll "What should we get for lunch?" "Sushi" "Burgers" "Thai" "Sandwiches" hidden</div>

                                    <p>To limit votes when creating a poll using dialogs, use this command:</p>
                                    <div className={styles.landing.command}>/quickpoll hidden</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.section}>
                            <div className={styles.landing.sectionGraphic}>
                                <video src="/assets/quickpoll-example.mp4" loop={true} autoPlay={true} muted={true}></video>
                            </div>
                            <div className={styles.landing.sectionContent}>
                                <div className={styles.landing.h1}>Simple Commands</div>
                                <div className={styles.landing.info}>
                                    <div className={styles.landing.h3}>A simple poll</div>
                                    <div className={styles.landing.command}>/quickpoll "How close are you to finishing your goals?" "I'm done" "Over half-way" "half-way" "haven't started.."</div>
                                    <div className={styles.landing.divider}></div>

                                    <div className={styles.landing.h3}>A poll with 1 vote per user</div>
                                    <div className={styles.landing.command}>/quickpoll "How close are you to finishing your goals?" "I'm done" "Over half-way" "half-way" "haven't started.." limit 1</div>
                                    <div className={styles.landing.divider}></div>

                                    <div className={styles.landing.h3}>An anonymous poll with 1 vote per user</div>
                                    <div className={styles.landing.command}>/quickpoll "How close are you to finishing your goals?" "I'm done" "Over half-way" "half-way" "haven't started.." limit 1 anonymous</div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.pricingSection}>

                            <div className={styles.landing.pricingTable}>
                                <div className={styles.landing.rowHeader}>
                                    <div className={styles.landing.item}>Feature</div>
                                    <div className={styles.landing.itemValue}>Free Version</div>
                                    <div className={styles.landing.itemValue}>Business Version</div>
                                </div>
                                <div className={styles.landing.row}>
                                    <div className={styles.landing.item}>Anonymous polls</div>
                                    <div className={styles.landing.itemValue}><b>&#x2714;</b></div>
                                    <div className={styles.landing.itemValue}><b>&#x2714;</b></div>
                                </div>
                                <div className={styles.landing.row}>
                                    <div className={styles.landing.item}>Add options to existing polls</div>
                                    <div className={styles.landing.itemValue}><b>&#x2714;</b></div>
                                    <div className={styles.landing.itemValue}><b>&#x2714;</b></div>
                                </div>
                                <div className={styles.landing.row}>
                                    <div className={styles.landing.item}>Limit user votes</div>
                                    <div className={styles.landing.itemValue}>&#215;</div>
                                    <div className={styles.landing.itemValue}><b>&#x2714;</b></div>
                                </div>
                                <div className={styles.landing.row}>
                                    <div className={styles.landing.item}>Hidden Polls</div>
                                    <div className={styles.landing.itemValue}>&#215;</div>
                                    <div className={styles.landing.itemValue}><b>&#x2714;</b></div>
                                </div>
                                <div className={styles.landing.row}>
                                    <div className={styles.landing.item}>Options per poll</div>
                                    <div className={styles.landing.itemValue}>10</div>
                                    <div className={styles.landing.itemValue}><b>70</b></div>
                                </div>
                                <div className={styles.landing.row}>
                                    <div className={styles.landing.item}>Create Polls with Slack Dialogs</div>
                                    <div className={styles.landing.itemValue}>2</div>
                                    <div className={styles.landing.itemValue}><b>Unlimited</b></div>
                                </div>
                                <div className={styles.landing.row}>
                                    <div className={styles.landing.item}>Monthly Votes</div>
                                    <div className={styles.landing.itemValue}>100</div>
                                    <div className={styles.landing.itemValue}><b>Unlimited</b></div>
                                </div>
                                <div className={styles.landing.row}>
                                    <div className={styles.landing.item}>Price</div>
                                    <div className={styles.landing.itemValue}>Free</div>
                                    <div className={styles.landing.itemValue}>$40USD/month</div>
                                </div>
                                <div className={styles.landing.row}>
                                    <div className={styles.landing.item}></div>
                                    <div className={styles.landing.itemValue}>
                                        <a className={styles.landing.addToSlack} href={process.env.SLACK_OAUTH_LINK}>
                                            add to slack
                                        </a>
                                    </div>
                                    <div className={styles.landing.itemValue}><a href={process.env.SLACK_SIGN_IN_LINK}>upgrade</a></div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.ctaSection}>
                            <p>Start making polls in your Slack workspace in 15 seconds</p>
                            <a className={styles.landing.addToSlack} href={process.env.SLACK_OAUTH_LINK}>
                                <img alt="Add to Slack" src="/assets/add-to-slack.png" />
                            </a>
                        </div>

                        <div className={styles.landing.divider}></div>

                        <div className={styles.landing.examples}>
                            <div className={styles.landing.h1}>Examples</div>
                            <div className={styles.landing.command}>/quickpoll "How close are you to finishing your goals?" "I'm done" "Over half-way" "half-way" "haven't started.."</div>
                            <div className={styles.landing.command}>/quickpoll "Do you want to get lunch?" ":no_good: Nope" ":blush: Yep!"</div>
                            <div className={styles.landing.command}>/quickpoll "When should we have the team meeting?" "9am" "10am" "11am"</div>
                            <div className={styles.landing.command}>/quickpoll "Do you think we will reach out goals this period?"" "Yes" "No"</div>
                            <div className={styles.landing.command}>/quickpoll "Pick your top 3 goals" "Featre 1" "Feature 2" "Feature 3" "Feature 4" "Feature 5"</div>
                        </div>

                    </div>
                </div>
            </div>

            <FooterContainer />
        </div>
    )
}