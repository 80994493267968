import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import api from 'Api'
import { actions, constants } from 'Custom';
const type = constants.actionTypes;

const userSagas = function*() {
    yield all([
        takeLatest(type.user.FETCH, function* (action) {
            try {
                const result = yield call(api.user.fetch);
                if (result.status === 'success') {
                    yield put(actions.user.fetchSuccess({ data: result.data }));
                } else if (!action.landing) {
                    throw {}
                } else {
                    throw new Error(result.message);
                }
            } catch (e) {
                yield put(actions.user.fetchFailure({ message: e.message }));
                yield call(api.user.logout);
                yield put(push('/'));
            }
        }),

        // fetch polls (paged)
        takeLatest(type.user.FETCH_POLLS, function* (action) {
            try {
                const result = yield call(api.user.fetchPolls, action.page);
                if (result.status === 'success') {
                    yield put(actions.user.fetchPollsSuccess({ polls: result.data }));
                } else {
                    yield put(actions.user.fetchPollsFailure({ message: result.message }));
                }
            } catch (e) {
                yield put(actions.user.fetchPollsFailure({ message: e.message }));
            }
        }),

        // update payment source (aka send stripe token)

        // subscribe

        // unsubcribe

        takeLatest(type.user.SUBSCRIBE, function* (action) {
            try {
                const result = yield call(api.user.subscribe, action.source);
                if (result.status === 'success') {
                    yield put(actions.user.fetch());
                    yield put(actions.user.subscribeSuccess());
                } else {
                    throw {};
                }
            } catch(e) {
                console.log(e);
            }
        }),

        takeLatest(type.user.UPDATE_PAYMENT_SOURCE, function* (action) {
            try {
                const result = yield call(api.user.updatePaymentSource, action.source);
                if (result.status === 'success') {
                    yield put(actions.user.fetch());
                    yield put(actions.user.updatePaymentSourceSuccess());
                } else {
                    throw {};
                }
            } catch(e) {
                console.log(e);
            }
        }),

        takeLatest(type.user.UNSUBSCRIBE, function* (action) {
            try {
                const result = yield call(api.user.unsubscribe);
                if (result.status === 'success') {
                    yield put(actions.user.fetch());
                    yield put(actions.user.unsubscribeSuccess());
                } else {
                    throw {};
                }
            } catch(e) {
                console.log(e);
            }
        }),

        takeLatest(type.user.RENEW_SUBSCRIPTION, function* (action) {
            try {
                const result = yield call(api.user.renewSubscription);
                if (result.status === 'success') {
                    yield put(actions.user.fetch());
                    yield put(actions.user.renewSubscriptionSuccess());
                } else {
                    throw {};
                }
            } catch(e) {
                console.log(e);
            }
        }),

        takeLatest(type.user.SEND_SUPPORT_MESSAGE, function* (action) {
            try {
                const result = yield call(api.user.sendSupportMessage, action.name, action.email, action.topic, action.message);
                if (result.status === 'success') {
                    yield put(actions.user.sendSupportMessageSuccess());
                } else {
                    throw {};
                }
            } catch(e) {
                console.log(e);
            }
        }),

        takeLatest(type.user.LOGOUT, function* (action) {
            yield call(api.user.logout);
            yield put(push('/'));
        }),
    ]);
}

export default function* () {
    yield userSagas();
}