const serverPath = `${process.env.API_PATH}`;
const defaultAuthHeader = () => ({
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem(process.env.LOCAL_STORAGE_AUTH_KEY) || `{}`).accessToken}`,
})

const defaultJsonHeaders = () => ({
    ...defaultAuthHeader(),
    'Content-Type': 'application/json'
});

const request = {
    raw: async (path, headers, method, body, blob = false) => {
        const response = await fetch( path, { credentials: 'include', headers, method, body } );

        if (response.status === 200) {
            return await (blob ? response.blob() : response.json());
        } else {
            if (response.status > 500) {
                return { status: 'failure', message: "Request failed. Please try again in 5 minutes." };
            }
            if (response.status === 401) {
                if (await request.refresh()) {
                    return await request.raw(path, { ...headers, ...defaultJsonHeaders() }, method, body);
                }
            }
            return response.json();
        }
    },
    get: async (path) => await request.raw(`${serverPath}${path}`, defaultJsonHeaders(), 'GET'),
    put: async (path, body = {}) => await request.raw(`${serverPath}${path}`, defaultJsonHeaders(), 'PUT', JSON.stringify(body)),
    patch: async (path, body = {}) => await request.raw(`${serverPath}${path}`, defaultJsonHeaders(), 'PATCH', JSON.stringify(body)),
    delete: async (path, body = {}) => await request.raw(`${serverPath}${path}`, defaultJsonHeaders(), 'DELETE', JSON.stringify(body)),
    post: async (path, body = {}) => await request.raw(`${serverPath}${path}`, defaultJsonHeaders(), 'POST', JSON.stringify(body)),
    download: async (path) => await request.raw(`${serverPath}${path}`, defaultJsonHeaders(), 'GET', undefined, true),
    refresh: async () => {
        const response = await fetch(
            `${serverPath}/auth/refresh`, 
            {
                credentials: 'include',
                headers: defaultJsonHeaders(),
                method: 'POST',
                body: JSON.stringify({ refreshToken: JSON.parse(localStorage.getItem(process.env.LOCAL_STORAGE_AUTH_KEY) || '{}').refreshToken })
            }
        );
        if (response.status === 200) {
            let result = await response.json();

            if (result.status === 'success') {
                // sucessfully refreshed the token
                localStorage.setItem(`${process.env.LOCAL_STORAGE_AUTH_KEY}`, JSON.stringify(result.data));
                return Promise.resolve(true);
            }
        }
        localStorage.clear();
        return Promise.resolve(false);
    }
};

export default request;