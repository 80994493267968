import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router';
import { actions, selectors } from 'Custom';

// import OAuth from 'Containers/oauth';
// import Success from 'Containers/success';
import Dashboard from 'Containers/user/dashboard';
import Polls from 'Containers/user/polls';
import Account from 'Containers/user/account';

class UserContainer extends React.Component {

    componentDidMount() {
        const { fetchUser, fetchPolls, fetchUserPending, fetchPollsPending } = this.props;
        // fetch the users data to validate the user
        if (!fetchUserPending) {
            fetchUser();
        }
        if (!fetchPollsPending) {
            fetchPolls();
        }
    }
    render() {
        const { user } = this.props;
        if (!user) {
            return null;
        }

        return (
            <div>
                <Switch>
                    {/* <Route exact path="/user" component={Dashboard} /> */}
                    <Route exact path="/user/polls" component={Polls} />
                    <Route exact path="/user/account" component={Account} />

                    <Redirect to="/user/polls" />
                </Switch>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        user: selectors.user.getData(state),
        fetchUserPending: selectors.user.fetchPending(state),
        fetchPollsPending: selectors.user.fetchPollsPending(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        fetchUser: actions.user.fetch,
        fetchPolls: actions.user.fetchPolls,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(UserContainer);