import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { actions, selectors } from 'Custom';
import ReactStripeModal from 'react-stripe-modal';
import AccountComponent from 'Components/user/account';
import autobind from 'class-autobind';

class AccountContainer extends React.Component {

    constructor(props) {
        super(props);

        autobind(this);
        this.state = {
            open: false,
            errorMessage: null,
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.user.subscription && !this.props.subscribePending && this.props.user.subscription) {
            this.closeStripeModal();
        }
    }

    // Open the stripe modal
    openStripeModal() {
        this.setState({
            open: true,
        });
    }

    // Close the stripe modal
    closeStripeModal() {
        this.setState({
            open: false,
        });
    }

    // Handle the submission of the stripe form
    onSubmit(token, type) {
        const { subscribe, updatePaymentSource } = this.props;

        switch (type) {
            case 'upgrade': {
                subscribe({ source: token.id });
                break;
            }
            case 'update': {
                updatePaymentSource({ source: token.id });
                break;
            }
        }
    }

    setStripeModalRef(ref) {
        this.reactStripeModalRef = ref;
    }

    render() {
        return (
            <AccountComponent {...this.props} {...this.state} openStripeModal={this.openStripeModal} onSubmit={this.onSubmit} closeStripeModal={this.closeStripeModal} />
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        user: selectors.user.getData(state),
        subscribePending: selectors.user.subscribePending(state),
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
        subscribe: actions.user.subscribe,
        updatePaymentSource: actions.user.updatePaymentSource,
        unsubscribe: actions.user.unsubscribe,
        renewSubscription: actions.user.renewSubscription,
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(AccountContainer);