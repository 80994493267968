import React from 'react';
import { Link } from 'react-router-dom';
import styles from 'Styles';
import Moment from 'react-moment';
import Sidebar from 'Containers/user/sidebar';
import AddToSlack from 'Components/user/add-to-slack';
import Emoji from "react-emoji-render";

export default props => {
    const { polls = [], user } = props;

    return (
        <div className={styles.main.wrapper}>
            <Sidebar />
            <div className={styles.content.container}>

                <div className={styles.content.content}>

                    <AddToSlack {...props} />

                    <div className={styles.content.title}>Polls</div>

                    {!polls || polls.length === 0 ? <p>You have not created any polls. Open Slack and create one! <Link to="/">Checkout the homepage for commands</Link></p> :
                    <table cellSpacing="0">
                        <thead>
                            <tr>
                                <th width="30%">Created</th>
                                <th width="30%">Question</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                        {polls && polls.map(poll => {
                            return (
                                <tr key={poll.id}>
                                    <td><Moment unix>{poll.created}</Moment></td>
                                    <td>{poll.question}<br />
                                        <small>
                                            {poll.config.anonymous ? <div>- Anonymous voting</div> : null} 
                                            {poll.config.limit > 0 ? <div>- Vote limit: {poll.config.limit}/person</div> : null} 
                                        </small>
                                    </td>
                                    <td>
                                        {poll.options.map(o => {
                                            const votes = poll.votes.filter(vote => vote.optionId === o.id);
                                            return (
                                                <div key={o.id} className={styles.content.option}>
                                                    <b>{votes.length} vote{votes.length !== 1 ? 's' : ''}</b> - <Emoji text={o.value} /> <br />
                                                    {poll.config.anonymous ? null : 
                                                        <div className={styles.content.votes}>
                                                            {votes.map(vote => {
                                                                return <a key={vote.id} href={`https://${user.teamDomain}.slack.com/team/${vote.userId}`}>@{vote.userName}</a>
                                                            })}
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>}
                </div>
            </div>
        </div>
    )
}