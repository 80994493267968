//
// Do not modify the below code, unless you know what you are doing
//
const REDUX_PREFIX = process.env.REDUX_PREFIX || '@@DEFAULT/';
const generateActionTypes = (prefix, actions) => actions.reduce((acc, action) => ({ ...acc, ...{ [action]: `${prefix}${action}`, [`${action}_SUCCESS`]: `${prefix}${action}_SUCCESS`, [`${action}_FAILURE`]: `${prefix}${action}_FAILURE` } }), {});
const generateInitialState = (custom = {}) => ({ ...custom, pending: {}, errors: {}, data: null })
export default (actionTypes = {}, customInitialStates = {}) => ({
    REDUX_PREFIX,
    actionConstants: actionTypes,
    actionTypes: Object.keys(actionTypes).reduce((acc, key) => {
        return {
            ...acc,
            [key]: generateActionTypes(`${REDUX_PREFIX}${key.toLocaleUpperCase()}/`, actionTypes[key]),
        }
    }, {}),
    initialStates: Object.keys(actionTypes).reduce((acc, key) => {
        return {
            ...acc,
            [key]: generateInitialState(customInitialStates[key] || {}),
        }
    }, {}),
});