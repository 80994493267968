import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'Custom';
import FooterComponent from 'Components/footer';

class FooterContainer extends React.Component {

    render() {
        return (
            <FooterComponent {...this.props} />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(FooterContainer);