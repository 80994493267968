import React from 'react';
import styles from 'Styles';

export default props => {
    if (props.user && !props.user.installed) {
        return (
            <div className={styles.content.addToSlack}>
                Hey! It looks like Quick Poll hasn't been installed on your Slack workspace
                <a href={process.env.SLACK_OAUTH_LINK}>
                    <img alt="Add to Slack" src="/assets/add-to-slack.png" />
                </a>
            </div>
        )
    }
    return null;
}