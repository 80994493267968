import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import styles from 'Styles';

export default props => {
    const { user, logout, toggleMobileMenu, displayMobileMenu } = props;
    return (
        <div className={styles.sidebar.container}>
            <Link to="/" className={styles.sidebar.logo}>
                <img src="/assets/logo_512.png" />
                Quick Poll
            </Link>
            <div className={`${styles.sidebar.menu} ${displayMobileMenu ? styles.sidebar.mobile : ''}`}>
                {/* <NavLink exact to="/user" className={styles.sidebar.item} activeClassName={styles.sidebar.active}>Dashboard</NavLink> */}
                <NavLink exact to="/user/polls" className={styles.sidebar.item} activeClassName={styles.sidebar.active}>Polls</NavLink>
                <NavLink exact to="/user/account" className={styles.sidebar.item} activeClassName={styles.sidebar.active}>My Account</NavLink>
                {!user.subscription ? <NavLink exact to="/user/account" className={`${styles.sidebar.item} ${styles.sidebar.upgrade}`} activeClassName={styles.sidebar.active}>Upgrade</NavLink> : null}
                <span className={styles.sidebar.item} onClick={logout}>Logout</span>
            </div>
            <div className={styles.sidebar.mobileBars} onClick={toggleMobileMenu}><FontAwesomeIcon icon={faBars} /></div>
        </div>
    )
}