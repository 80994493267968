import main from './main.less';
import landing from './landing.less';
import sidebar from './sidebar.less';
import content from './content.less';

export default {
    main,
    landing,
    sidebar,
    content,
}