import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'Custom';
import PrivacyPolicyComponent from 'Components/privacy-policy';

class PrivacyPolicyContainer extends React.Component {
    render() {
        return (
            <PrivacyPolicyComponent {...this.props} />
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
    }
}

const mapActionsToProps = (dispatch) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapActionsToProps
)(PrivacyPolicyContainer);